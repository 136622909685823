<template>
	<v-expansion-panel>
		<v-expansion-panel-header expand-icon="fal fa-plus">
			{{ $t('filters.provinceTitle') }}
			<div v-if="selectedProvinces.length" style="padding-left: 0.5rem">
				<span class="province-filter__num-selecteds">{{ selectedProvinces.length }}</span>
			</div>
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<v-checkbox
				v-for="item in provinces"
				:key="item.province"
				:label="item.name"
				:value="ischecked(item.name)"
				v-model="item.checked"
				@change="isSelected(item.name)"
			></v-checkbox>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import { search } from '@/api/common.js';

export default {
	name: 'ProvinceFilter',
	data() {
		return {
			provinces: [],
			selectedProvinces: []
		};
	},
	mounted() {
		this.getProvinces();
		this.getSectorsFromQuery();
	},
	components: {},
	methods: {
		getProvinces: async function () {
			const body = {
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							data: this.$store.state.eventInfo.evenid,
							field: 'event',
							op: 'eq'
						}
					]
				},
				model: 'catalogprovince',

				order: [
					{
						column: 'name',
						direction: 'asc'
					}
				],
				page: 1,
				queryFields: [''],
				queryText: '',
				rows: 999999999
			};
			let data = await search(body);
			this.provinces = data.data;

			this.provinces.forEach((province) => {
				this.selectedProvinces.forEach((selectedProvince) => {
					if (province.name === selectedProvince) {
						province.checked = true;
					}
				});
			});
		},

		addProvince: function (province) {
			this.selectedProvinces.push(province);
			const query = Object.assign({}, this.$route.query);
			delete query.province;
			this.$router.replace({ query: { province: this.selectedProvinces } });
		},

		removeProvince: function (province) {
			const index = this.selectedProvinces.indexOf(province);
			if (index > -1) {
				this.selectedProvinces.splice(index, 1);
			}
			const query = Object.assign({}, this.$route.query);
			delete query.province;
			this.$router.replace({ query });
			this.$router.push({ query: { province: this.selectedProvinces } });
		},

		isSelected(province) {
			let isSelected = false;
			this.selectedProvinces.forEach((element) => {
				if (element === province) {
					isSelected = true;
				}
			});

			isSelected ? this.removeProvince(province) : this.addProvince(province);
		},
		ischecked(province) {
			let isSelected = false;
			this.selectedProvinces.forEach((element) => {
				if (element === province) {
					isSelected = true;
				}
			});
			return isSelected;
		},
		getSectorsFromQuery: function () {
			if (this.$route.query.province) {
				const data = this.$route.query.province;
				if (Array.isArray(data)) this.selectedProvinces = data;
				else this.selectedProvinces.push(data);
			}
		}
	},

	watch: {
		'$route.query': function () {
			if (!this.$route.query.province) {
				this.selectedProvinces = [];
				this.getProvinces();
			}
		}
	}
};
</script>


<style lang="scss" scoped>
.province-filter {
	&__num-selecteds {
		background-color: #2498ff;
		border-radius: 0.8em;
		-moz-border-radius: 0.8em;
		-webkit-border-radius: 0.8em;
		color: #fff;
		display: inline-block;
		line-height: 1rem;
		text-align: center;
		width: 1rem;
		font-size: 0.75rem;
	}
}
</style>

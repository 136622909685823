import { render, staticRenderFns } from "./ExhibitorsCatalog.vue?vue&type=template&id=ba95349e&scoped=true"
import script from "./ExhibitorsCatalog.vue?vue&type=script&lang=js"
export * from "./ExhibitorsCatalog.vue?vue&type=script&lang=js"
import style0 from "./ExhibitorsCatalog.vue?vue&type=style&index=0&id=ba95349e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba95349e",
  null
  
)

export default component.exports
<template>
	<div class="exhibitors-catalog">
		<div class="exhibitors-catalog__container">
			<table style="width: 100%">
				<thead class="exhibitors-catalog__header">
					<tr>
						<th class="exhibitors-catalog__header-text" style="width: 13.65%"></th>
						<th class="exhibitors-catalog__header-text" style="width: 30.5%; cursor: pointer" @click="sortTable('name')">
							{{ $t('exhibitors.name') }}
							<i v-if="sortBy == 'name'" :class="ascending ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
						</th>
						<th class="exhibitors-catalog__header-text" style="width: 26%">{{ $t('exhibitors.sectors') }}</th>
						<th class="exhibitors-catalog__header-text" style="width: 17.25%; cursor: pointer" @click="sortTable('country')">
							{{ $t('exhibitors.country') }}
							<i v-if="sortBy == 'country'" :class="ascending ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
						</th>
						<th class="exhibitors-catalog__header-text" style="width: 12.6%"></th>
					</tr>
				</thead>
				<tbody v-if="!showProgress && this.exhibitors.length > 0">
					<tr v-for="(item, i) in paginated" :key="item.exhibitor">
						<td class="exhibitors-catalog__body-row-image">
							<router-link :to="{ name: 'ExhibitorDetails', params: { id: item.exhibitor } }">
								<img v-if="item.logourl" :src="item.logourl" alt="logo" class="exhibitors-catalog__body-row-image--size" />
								<img v-else-if="defaultPhoto" :src="defaultPhoto" alt="logo" class="exhibitors-catalog__body-row-image--size" />
								<span v-else>{{ $t('exhibitors.noimage') }}</span>
							</router-link>
						</td>
						<td class="exhibitors-catalog__body-row-name">
							<span
								v-for="type in JSON.parse(item.typesjson)"
								:key="type.id"
								class="exhibitors-catalog__body-row-name-PREMIUM"
								:style="type.color ? `background-color: ${type.color}` : 'background-color: #d4af37;'"
							>
								{{ type.name }}
							</span>
							<router-link :to="{ name: 'ExhibitorDetails', params: { id: item.exhibitor } }" style="text-decoration: none">
								<div class="exhibitors-catalog__body-row-name-text">{{ item.name }}</div>
							</router-link>
						</td>
						<td class="exhibitors-catalog__body-row-sector">
							<div class="exhibitors-catalog__body-row-sector-container">
								<span
									v-if="item.sectors"
									class="exhibitors-catalog__body-row-sector-text"
									:id="'row_' + i"
									@click="showMoreSectors(item.sectorsname, i)"
								>
									{{ printSectors(item.sectorsname) }}
								</span>
							</div>
						</td>
						<td class="exhibitors-catalog__body-row-country">
							<div class="exhibitors-catalog__body-row-country-container">
								<v-img
									class="exhibitors-catalog__body-row-country-flag"
									:src="require('@/assets/images/paises/' + item.countryid + '.png')"
									max-width="1.5rem"
									alt="pais"
								></v-img>
								<span>{{ item.country }}</span>
							</div>
						</td>
						<td class="exhibitors-catalog__body-row-social">
							<div class="exhibitors-catalog__body-row-social-container">
								<AppointmentsModal v-if="item.exhibitor" :exhibitorId="item.exhibitor" :enabled="item.enableappointments" flat />
								<Modal :exhibitors="item" />
								<ShareDropdown :url="getShareURL(item.exhibitor)" :exhibitorName="item.name" />
							</div>
						</td>
					</tr>
				</tbody>
				<tbody v-else-if="exhibitors.length == 0 && !showProgress">
					<tr style="border-bottom: 1px solid #e3e7e8">
						<td colspan="100%">
							<div class="exhibitors-catalog__body-row-container">
								<div class="exhibitors-catalog__body-row-container-titol">{{ $t('exhibitors.results_tittle') }}</div>
								<div class="exhibitors-catalog__body-row-container-text">{{ $t('exhibitors.results_text') }}</div>
							</div>
						</td>
					</tr>
				</tbody>
				<tbody v-else>
					<tr>
						<td colspan="100%">
							<div class="loading-circle">
								<v-progress-circular indeterminate :color="primaryColor"></v-progress-circular>
							</div>
						</td>
					</tr>
				</tbody>
			</table>

			<div class="exhibitors-catalog__pagination">
				<span class="exhibitors-catalog__pagination-info">
					{{ current * pageSize - pageSize + 1 }} - {{ current * pageSize }} de {{ exhibitors.length }}</span
				>
				<button @click="goFirst()" class="exhibitors-catalog__pagination-button"><i class="far fa-arrow-to-left"></i></button>
				<button @click="goPrev()" class="exhibitors-catalog__pagination-button"><i class="far fa-arrow-left"></i></button>
				<div class="exhibitors-catalog__pagination-select">
					<v-select v-model="current" :items="totalPages()" outlined append-icon=""></v-select>
				</div>
				<button @click="goNext()" class="exhibitors-catalog__pagination-button"><i class="far fa-arrow-right"></i></button>
				<button @click="goLast()" class="exhibitors-catalog__pagination-button"><i class="far fa-arrow-to-right"></i></button>
			</div>
		</div>
	</div>
</template>

<script>
// import { search } from '@/api/common.js';
import { getDocument } from '@/api/common.js';
import ShareDropdown from '@/components/common/ShareDropdown.vue';
import Modal from '@/components/common/Modal.vue';
import AppointmentsModal from '@/components/appointments/AppointmentsModal.vue';

export default {
	name: 'ExhibitorsCatalog',
	props: {
		exhibitors: {
			type: [Array, Object],
			required: true
		}
	},

	data() {
		return {
			IconFav: require('@/assets/icons/star.svg'),
			IconShare: require('@/assets/icons/share.svg'),
			catalog: [],
			sectors: [],
			defaultPhoto: null,
			ascending: true,
			sortBy: 'name',
			current: 1,
			pageSize: 20,
			showProgress: false,
			favBtnProductSelected: false
		};
	},
	components: { ShareDropdown, Modal, AppointmentsModal },
	created() {
		this.subscribeToEvents();
	},
	mounted() {
		this.getDefaultPhoto();
	},
	methods: {
		getCatalog: async function () {
			const data = this.splitSectors(this.exhibitors);
			this.exhibitors = data;
		},

		splitSectors: function (data) {
			data.map((exhibitor) => {
				if (exhibitor.sectorsname && !Array.isArray(exhibitor.sectorsname)) {
					exhibitor.sectorsname = exhibitor.sectorsname.split(',');
				}
			});
			return data;
		},

		printSectors: function (inArray) {
			if (inArray.length > 2) {
				return inArray[0] + ', ' + inArray[1] + '...+' + (inArray.length - 2);
			} else {
				return inArray.join(', ');
			}
		},

		showMoreSectors: function (sectors, i) {
			const id = 'row_' + i;
			document.getElementById(id).innerHTML = sectors.join(', ');
		},

		getDefaultPhoto: async function () {
			const data = await getDocument('event', this.$store.state.eventInfo.evenid, 'event_app_primary');
			this.defaultPhoto = data[0].url;
		},

		sortTable: function (colName) {
			if (this.sortBy === colName) {
				this.ascending = !this.ascending;
			} else {
				this.ascending = true;
				this.sortBy = colName;
			}

			let ascending = this.ascending;

			this.exhibitors.sort(function (a, b) {
				if (a[colName] > b[colName]) {
					return ascending ? 1 : -1;
				} else if (a[colName] < b[colName]) {
					return ascending ? -1 : 1;
				}
				return 0;
			});
		},

		goPrev() {
			if (this.current > 1) this.current--;
		},
		goNext() {
			if (this.current < Math.ceil(this.exhibitors.length / this.pageSize)) this.current++;
		},
		goFirst() {
			this.current = 1;
		},
		goLast() {
			this.current = Math.ceil(this.exhibitors.length / this.pageSize);
		},
		totalPages() {
			return Array.from({ length: Math.ceil(this.exhibitors.length / this.pageSize) }, (item, index) => (item = index + 1));
		},
		subscribeToEvents() {
			this.$puiEvents.$on('searching-true_catalogexhibitor', () => {
				this.showProgress = true;
			});
			this.$puiEvents.$on('searching-false_catalogexhibitor', () => {
				this.showProgress = false;
			});
		},

		getShareURL: function (exhibitorId) {
			return window.location.origin + this.$router.resolve({ name: 'ExhibitorDetails', params: { id: exhibitorId } }).href;
		}
	},
	computed: {
		indexStart() {
			return (this.current - 1) * this.pageSize;
		},
		indexEnd() {
			return this.indexStart + this.pageSize;
		},
		paginated() {
			return this.exhibitors.slice(this.indexStart, this.indexEnd);
		},
		primaryColor: function () {
			return this.$store.state.eventInfo.evenappprimarycolor
				? this.$store.state.eventInfo.evenappprimarycolor
				: this.$store.state.eventInfo.defaultColor;
		}
	},

	watch: {
		exhibitors: 'getCatalog'
	}
};
</script>


<style lang="scss" scoped>
table {
	border-collapse: collapse;
}

tr {
	border-top: 1px solid #e3e7e8;
}

td {
	height: 5.625rem;
}

.loading-circle {
	display: flex;
	justify-content: center;
	text-align: center;
}

.fas.fa-chevron-up,
.fas.fa-chevron-down {
	margin-left: 1rem;
}

.exhibitors-catalog {
	background-color: #f3f5f5;
	width: 100%;
	padding: 2.5rem;
}

.exhibitors-catalog__container {
	background-color: #fff;
	border: 1px solid #e3e7e8;
	border-radius: 8px;
}

.exhibitors-catalog__header {
	&-text {
		padding: 1.375rem 0;
		text-align: start;
	}
}

.exhibitors-catalog__body-row-image {
	padding: 0 1.25rem;
	text-align: center;

	&--size {
		margin: 0 auto;
		width: 7.125rem;
		max-height: 3.875rem;
		object-fit: cover;
	}
}

.exhibitors-catalog__body-row-name {
	&-PREMIUM {
		color: #fff;
		border-radius: 6px;
		padding: 0.125rem 0.375rem;
		font-weight: 500;
		font-size: 0.75rem;
		margin-right: 0.5rem;
	}

	&-text {
		font-family: Cabin;
		font-weight: bold;
		font-size: 1rem;
		color: #293133;
	}
}

.exhibitors-catalog__body-row-sector {
	&-text {
		font-size: 1rem;
		color: #575e60;
		width: 15.5rem;
		padding-top: 35px;
		padding-bottom: 35px;
	}
	&-container {
		display: flex;
		align-items: center;
	}
}

.exhibitors-catalog__body-row-country {
	&-container {
		font-size: 1rem;
		color: #575e60;
		display: flex;
		align-items: center;
	}

	&-flag {
		margin-right: 0.5rem;
		border-radius: 3px;
	}
}

.exhibitors-catalog__body-row-social {
	&-item {
		padding: 0 0.75rem;
	}
	&-container {
		display: flex;
		align-items: center;
		padding-right: 1.5rem;
	}
}
.exhibitors-catalog__body-row-notext {
	display: flex;
	justify-content: center;
	padding: 8px 10px;
	border: 1px solid #ddd;
	font-size: 14px !important;
	color: #464f63;
	font-weight: 400;
	background-color: #f9f9f9;
}
.exhibitors-catalog__body-row-notext:hover {
	background-color: var(--main-10) !important;
}

.exhibitors-catalog__body-row-container-titol {
	display: flex;
	justify-content: center;
	font-size: 20px !important;
	color: black;
	font-weight: bold;
	text-align: center;
	margin-bottom: 0.5rem;
}

.exhibitors-catalog__body-row-container-text {
	display: flex;
	justify-content: center;
	font-size: 14px !important;
	color: #464f63;
	font-weight: 400;
	text-align: center;
}
.exhibitors-catalog__pagination {
	display: flex;
	justify-content: center;
	align-items: baseline;
	padding-top: 1rem;

	&-info {
		margin-right: 0.5rem;
	}

	&-button {
		padding: 0.5rem 0.75rem;
		margin: 0.125rem;
		border-radius: 6px;
		background-color: #f5f7f7;
	}

	&-select {
		width: 4rem;
		margin: 0 0.25rem;
	}
}
</style>

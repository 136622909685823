<template>
	<v-expansion-panel>
		<v-expansion-panel-header expand-icon="fal fa-plus">
			{{ $t('filters.pavilionTitle') }}
			<div v-if="selectedPavilions.length" style="padding-left: 0.5rem">
				<span class="pavilion-filter__num-selecteds">{{ selectedPavilions.length }}</span>
			</div>
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<div v-for="pavilion in selectedPavilions" :key="pavilion" class="pavilion-filter__selected">
				<i style="cursor: pointer" @click="removePavilion(pavilion)" class="fal fa-plus"></i>
				<span class="pavilion-filter__selected-name">{{ pavilion }}</span>
			</div>
			<div class="pavilion-filter__bottom">
				<v-dialog v-model="dialog" width="676">
					<template v-slot:activator="{ on, attrs }">
						<v-btn class="pavilion-filter__bottom-button" depressed v-bind="attrs" v-on="on">
							{{ $t('filters.pavilionButton') }}
						</v-btn>
					</template>
					<v-card>
						<div style="position: relative">
							<div style="position: absolute; right: 0; margin-right: 1rem; margin-top: 1rem">
								<img :src="IconClose" alt="close" @click="dialog = false" style="cursor: pointer" />
							</div>
						</div>
						<v-card-title class="pavilion-filter__modal-title"> {{ $t('filters.pavilionModalTitle') }} </v-card-title>

						<v-card-text style="padding: 0">
							<div class="pavilion-filter__modal-search">
								<div style="padding: 0 2.5rem; width: 100%">
									<SearchBar
										:label="$t('filters.modalSearchLabel')"
										model="catalogpavilion"
										v-model="pavillions"
										light
										style="width: 100%"
										:key="refreshSearchbar"
									/>
								</div>
							</div>
							<div class="pavilion-filter__modal-body">
								<v-checkbox
									v-for="item in pavillions"
									:key="item.exhibitorcount"
									:label="item.name"
									:value="ischecked(item.name)"
									v-model="item.checked"
									@change="isSelected(item.name)"
									style="border-bottom: 1px solid #e3e7e8"
								></v-checkbox>
							</div>
						</v-card-text>

						<v-card-actions class="pavilion-filter__modal-button">
							<v-btn outlined @click="dialog = false"> {{ $t('filters.modalSubmit') }} </v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</div>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import SearchBar from '@/components/common/SearchBar.vue';
import { search } from '@/api/common.js';

export default {
	name: 'PavilionFilter',

	data() {
		return {
			IconClose: require('@/assets/icons/close.svg'),
			dialog: false,
			pavillions: [],
			selectedPavilions: [],
			refreshSearchbar: 0
		};
	},
	mounted() {
		this.getPavillionsFromQuery();
	},

	components: { SearchBar },
	methods: {
		getPavillion: async function () {
			const body = {
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							data: this.$store.state.eventInfo.evenid,
							field: 'event',
							op: 'eq'
						}
					]
				},
				model: 'catalogpavilion',

				order: [
					{
						column: 'name',
						direction: 'asc'
					}
				],
				page: 1,
				queryFields: ['name'],
				queryText: '',
				rows: 999999999
			};
			let data = await search(body);
			this.pavillions = data.data;
			this.pavillions.forEach((pavillion) => {
				this.selectedPavilions.forEach((selectedPavillion) => {
					if (pavillion.name === selectedPavillion) {
						pavillion.checked = true;
					}
				});
			});
			console.log(this.pavillions);
		},
		addPavilion: function (pavillionname) {
			this.selectedPavilions.push(pavillionname);
			const query = Object.assign({}, this.$route.query);
			delete query.pavilion;
			this.$router.replace({ query: { pavilion: this.selectedPavilions } });
		},

		removePavilion: function (pavillionname) {
			const index = this.selectedPavilions.indexOf(pavillionname);
			if (index > -1) {
				this.selectedPavilions.splice(index, 1);
			}
			const query = Object.assign({}, this.$route.query);
			delete query.pavilion;
			this.$router.replace({ query });
			this.$router.push({ query: { pavilion: this.selectedPavilions } });
		},

		isSelected(pavillionname) {
			let isSelected = false;
			this.selectedPavilions.forEach((element) => {
				if (element === pavillionname) {
					isSelected = true;
				}
			});

			isSelected ? this.removePavilion(pavillionname) : this.addPavilion(pavillionname);
		},
		ischecked(pavillionname) {
			let isSelected = false;
			this.selectedPavilions.forEach((element) => {
				if (element === pavillionname) {
					isSelected = true;
				}
			});
			return isSelected;
		},

		getPavillionsFromQuery: function () {
			if (this.$route.query.pavilion) {
				const data = this.$route.query.pavilion;
				if (Array.isArray(data)) this.selectedPavilions = data;
				else this.selectedPavilions.push(data);
			}
			console.log(this.selectedPavilions);
		}
	},

	watch: {
		dialog(newVal) {
			if (newVal) {
				this.refreshSearchbar++;
				this.getPavillion();
			}
		},

		'$route.query': function () {
			if (!this.$route.query.pavilion) this.selectedPavilions = [];
		}
	}
};
</script>


<style lang="scss" scoped>
.fal.fa-plus {
	transform: rotate(45deg);
	font-size: 1.125rem;
	color: #575e60;
}

.pavilion-filter {
	&__num-selecteds {
		background-color: #2498ff;
		border-radius: 0.8em;
		-moz-border-radius: 0.8em;
		-webkit-border-radius: 0.8em;
		color: #fff;
		display: inline-block;
		line-height: 1rem;
		text-align: center;
		width: 1rem;
		font-size: 0.75rem;
	}
	&__selected {
		display: flex;
		align-items: center;

		&-name {
			font-size: 14px;
			color: #575e60;
			padding-left: 0.25rem;
		}
	}

	&__bottom {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 1rem;

		&-button {
			background-color: #fff !important;
			border: 1px solid #e3e7e8;
			border-radius: 8px !important;

			font-family: Cabin;
			font-weight: bold;
			font-size: 16px;
			color: #575e60;
			padding: 1 25rem 1rem !important;
		}
	}

	&__modal {
		&-title {
			padding-top: 2.25rem !important;
			padding-left: 2.5rem !important;
			font-family: Cabin;
			font-weight: bold !important;
			font-size: 26px !important;
			color: #293133;
		}

		&-search {
			display: flex;
			justify-content: center;
			box-shadow: 0px 8px 8px -8px rgb(0 0 0 / 18%);
		}

		&-body {
			padding: 0.5rem 2.5rem;
		}

		&-button {
			padding-right: 2.5rem !important;
			padding-bottom: 1.5rem !important;
			display: flex;
			justify-content: flex-end;
		}
	}
}
</style>
